import { IntegrationApplication } from '@wix/members-area-app-definitions';

import { IntegrationApplicationWithWidgetId } from '../../types';

export const groupDefinitionsByMethod = (definitions: IntegrationApplicationWithWidgetId[]) => {
  const definitionsGroupedByMethod = {
    widgetPlugins: [] as IntegrationApplicationWithWidgetId[],
    siteApps: [] as IntegrationApplication[],
  };

  definitions.forEach((definition) => {
    if (definition.method === 'addApplicationToSite') {
      definitionsGroupedByMethod.siteApps.push(definition);
    } else {
      definitionsGroupedByMethod.widgetPlugins.push(definition);
    }
  });

  return definitionsGroupedByMethod;
};
