import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-app-definitions';

import * as constants from '../../../constants';
import * as menusWrapper from '../../../wrappers/menus';
import { createBIService } from '../../../../utils/bi';
import { installSiteApplications } from '../../../platform-api/addApplications';
import { getVerticalsApps } from '../../../services/applicationState';
import { getDefinitionsWithWidgetId } from '../services/widget-plugins';
import { addApplicationsToSlots } from '../services/members-area-page';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import { createMenuItemV2 } from '../../../services/menus';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { IntegrationApplicationWithWidgetId } from '../../types';
import { groupDefinitionsByMethod } from './definitions';

export const installApps = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  const { widgetPlugins, siteApps } = groupDefinitionsByMethod(definitions);

  if (widgetPlugins.length > 0) {
    await addApplicationsToSlots(editorSDK, widgetPlugins);
  }

  if (siteApps.length > 0) {
    await installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate: false });
  }

  await addLoginBarMenuItems(editorSDK, definitions);
};

export const addLoginBarMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);
  const filteredDefinitions = definitions.filter((app) => app.showInLoginMenu);

  const appsDatas = await Promise.all(
    filteredDefinitions.map((definition) =>
      getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId }),
    ),
  );

  const menuItems = appsDatas.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }
    return createMenuItemV2({
      appBaseUrl: membersAreaPage.pageUriSEO,
      isPrivate: !definition.social,
      title: appDataComponent.name,
      pageUriSEO: definition.urlOverride || appDataComponent.name.toLowerCase().replace(' ', '-'),
    });
  });

  await menusWrapper.addMenuItems({ editorSDK, menuId: constants.MENU_IDS.LOGIN_MENU_ID, items: menuItems });
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter((app) => {
    return app.shouldInstallInitially !== false;
  });
  const definitions = getDefinitionsWithWidgetId(integrationAppsToInstall);

  if (definitions.length > 0) {
    await installApps(editorSDK, definitions);
  }

  // TODO: Set BWP on install

  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
