import { EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';
import { IntegrationApplicationWithWidgetId, MembersAreaPagePublicApi, WidgetPluginPointer } from '../../types';

const getMembersAreaPagePublicAPI = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });

  if (membersAreaPagePublicApi) {
    return membersAreaPagePublicApi as MembersAreaPagePublicApi;
  }

  throw new Error('[MAV2] Members Area Page PublicAPI is not found');
};

export const getInstalledApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getInstalledWidgetPlugins();
};

export const getPublicApplications = async (editorSDK: EditorSDK) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.getPublicApplications();
};

export const addApplicationsToSlots = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplicationWithWidgetId[],
) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.addWidgetsPlugins(applications);
};

export const removeApplicationsFromSlots = async (editorSDK: EditorSDK, applications: WidgetPluginPointer[]) => {
  const membersAreaPageAPI = await getMembersAreaPagePublicAPI(editorSDK);
  return membersAreaPageAPI.removeWidgetsPlugins(applications);
};
