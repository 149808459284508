import { ComponentRef, EditorReadyFn, EditorReadyOptions, EditorSDK, PageRef } from '@wix/platform-editor-sdk';

import * as menusWrapper from '../../wrappers/menus';
import * as constants from '../../constants';
import { interactionEnded, interactionFailed, interactionStarted } from '../../../utils/monitoring';
import { APP_TOKEN, SANTA_MEMBERS_APP_ID, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../constants';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { allSettled } from '../../../utils/promises';
import { createController } from '../../wrappers/controllers';
import { createLoginMenu, createLoginIconsMenu } from '../../wrappers/menus';
import { addLoginButton, registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { getIsResponsiveEditor } from '../../services/applicationState';
import { registerAlwaysAvailableApps } from './integration';
import { createMenuItemV2 } from '../../services/menus';
import { getMembersAreaPage } from '../../wrappers/pages';
import { getDataByAppDefId } from '../../wrappers/tpa';

const addMembersAreaPage = (editorSDK: EditorSDK) => {
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: true,
    isSilent: true,
  });
};

const getRefsForLoginMenu = async (editorSDK: EditorSDK) => {
  const [masterRef, headerRef]: [PageRef, ComponentRef] = await allSettled([
    editorSDK.siteSegments.getSiteStructure(APP_TOKEN),
    editorSDK.siteSegments.getHeader(APP_TOKEN),
  ]);
  let controllerRef: ComponentRef | null = null;

  if (!getIsResponsiveEditor()) {
    controllerRef = await createController(editorSDK, masterRef);
  }

  return { headerRef, controllerRef };
};

const addLoginMenus = async (editorSDK: EditorSDK) => {
  const { headerRef, controllerRef } = await getRefsForLoginMenu(editorSDK);

  await Promise.all([
    createLoginMenu(editorSDK),
    createLoginIconsMenu(editorSDK),
    addLoginButton(editorSDK, controllerRef!, headerRef),
  ]);
};

const addMyAccountLoginMenuItem = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  const myAccountDefinition = constants.getMyAccountInstallDefinition(options.origin);
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  if (!membersAreaPage) {
    throw new Error('Members Area page does not exist after its installation');
  }

  const myAccountTPA = await getDataByAppDefId({ editorSDK, appDefinitionId: constants.MY_ACCOUNT_APP_DEF_ID });
  // @ts-expect-error: Platform types are missing and we have no other source for apps dev center texts
  const myAccountWidget = await myAccountTPA.widgets[constants.MY_ACCOUNT_WIDGET_ID];

  const menuItem = createMenuItemV2({
    appBaseUrl: membersAreaPage.pageUriSEO,
    isPrivate: !myAccountDefinition.social,
    title: myAccountWidget.appPage.name,
    pageUriSEO: myAccountDefinition.urlOverride,
  });

  await menusWrapper.updateMenuItems({ editorSDK, menuId: constants.MENU_IDS.LOGIN_MENU_ID, items: [menuItem] });
};

const maybeInstallMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  if (options.firstInstall) {
    await addMembersAreaPage(editorSDK);
    await addLoginMenus(editorSDK);
    await addMyAccountLoginMenuItem(editorSDK, options);
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  try {
    interactionStarted('editorReady');
    await maybeInstallMembersArea(editorSDK, options);
    await registerToComponentAddedToStageEvent(editorSDK);
    await registerAlwaysAvailableApps(editorSDK);
    startSequentialPromises();
    interactionEnded('editorReady');
  } catch (error: any) {
    interactionFailed('editorReady', error);
    console.error('Members Area installation failed');
    console.error(error);
    stopSequentialPromises();
    throw error;
  }
};
